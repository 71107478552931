.country-set-form-container {
  padding: 30px;
  max-width: 500px;
  margin: 0 auto;
  .warning {
    margin: 20px 0;
  }
  form {
    margin-bottom: -30px;
    & > * {
      margin-bottom: 30px;
    }
    .buttons {
      & > * {
        margin-right: 10px;
      }
    }
  }
}
