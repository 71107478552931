@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

html {
  width: 100%;
  min-height: 100%;
  font-size: 14px;
}

body {
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  width: 100%;
  min-height: 100vh;
  font-size: 14px;
  letter-spacing: 0.2px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: inherit;
  text-decoration: none;
  &:focus {
    outline: none;
  }
}

.Toastify__toast-body {
  font-size: 1.14rem;
}

.Toastify__toast {
  font-family: inherit;
  letter-spacing: inherit;
}

:root {
  --toastify-color-success: #fce7ed;
  --toastify-text-color-success: #e91850;
  --toastify-color-progress-success: #e91850;
}

.Toastify__toast--success {
  .Toastify__close-button {
    color: var(--toastify-color-progress-success);
  }
}

.Toastify__progress-bar-theme--colored.Toastify__progress-bar--success {
  background: var(--toastify-color-progress-success);
}
